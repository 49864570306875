// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Widget from "../components/widget"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import ProjectsData from "../../content/my-projects.json"
import CareerData from "../../content/career.json"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  },
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          title: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const quadrantCSS = {
    padding: '10px'
  }

  return (
    <Layout location={location} title={`${siteTitle}`}>
      <SEO title="Home | Rohit Sharma" />

      <div className="row" style={{marginTop: rhythm(1)}}>
        <div className="col-sm-12 quadrant" style={quadrantCSS}>
          <Widget title="Blogs" data={
            posts.map((post) => {
              return {
                excerpt: post.node.excerpt,
                title: post.node.frontmatter.title,
                relativeLink: post.node.fields.slug
              }
            })
          }  moreLink="/blog" />
        </div>
      </div>

      <div className="row" style={{marginTop: rhythm(1)}}>
        <div className="col-sm-12 quadrant" style={quadrantCSS}>
          <Widget title={CareerData.title} data={CareerData.projects.slice(0,3)} moreLink={CareerData.moreLink}/>
        </div>
      </div>
      <div className="row" style={{marginTop: rhythm(1)}}>
        <div className="col-sm-12 quadrant" style={quadrantCSS}>
          <Widget title={ProjectsData.title} data={ProjectsData.projects.slice(0,6)} moreLink={ProjectsData.moreLink}/>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit:6, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`