import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

const Widget = ({ title, data, moreLink }) => {
  const style = {
    height: '130px',
    overflow: 'hidden',
    maxHeight: '140px',
    minHeight: '100%',
    marginBottom: '10px'
  }

  let idx = 0
  let len = data.length

  let batches = []

  while(idx < len){
    batches.push(data.slice(idx, idx+3))
    idx += 3
  }

  return (
    <div className="container">
      <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">{title}</h2>
      <div className="divider-custom">
        <div className="divider-custom-line"></div>
        <div className="divider-custom-icon">
          <svg className="svg-inline--fa fa-star fa-w-18" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
        </div>
        <div className="divider-custom-line"></div>
      </div>

      {
        batches.map((batch, j) => {
          return (
            <div className="row" key={j}>
            {
              batch.map((data, i) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-5" key={i} style={{height: '120px'}}>
                    <article style={style}>
                      <header>
                        <h4 style={{ marginBottom: rhythm(1 / 4), fontSize: rhythm(0.8) }}>
                          {
                            data.relativeLink.length ? 
                            <a style={{ boxShadow: `none` }} href={data.relativeLink}>{data.title}</a> :
                            data.title
                          }
                        </h4>
                      </header>
                      <section>
                        <p className="text-truncate"
                          dangerouslySetInnerHTML={{
                            __html: data.excerpt
                          }}
                        />
                      </section>
                    </article>
                  </div>
                )
              })
            }
            </div>
          )
        })
      }

      <div className="row text-center"><div className="col-sm-12">
        <a className="btn btn-primary" href={moreLink}>Continue Reading</a>
      </div></div>
    </div>
  )
}

export default Widget